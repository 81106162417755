@import "../base/colors"
@import "../base/mobile"

@keyframes Loading
    0%, 50%, 100%
        transform: scale(0)
    20%
        transform: scale(3.5)

.loader
    height: 300px
    position: relative
    &.full-page
        position: fixed
        top: 0
        left: 0
        bottom: 0
        right: 0
        z-index: 999
        height: auto
        background-color: #F5F5F588
        &.white
            background: #FFFFFF
    &.segment
        position: absolute !important
        .wrp
            top: 350px
    .inside
        margin: 0 auto
        text-align: center
        position: absolute
        left: 0
        right: 0
        top: 45%
        display: flex
        width: 100%
        justify-content: center
        span
            background-color: $color-brand
            box-shadow: 0 0 3px #f9a51a55
            width: 6px
            height: 6px
            border-radius: 50%
            margin: 15px
            animation: Loading 1.2s ease-in-out infinite
            transform-style: preserve-3d
            transform-origin: center
            position: relative
            display: block
            transform: scale(0)
            &:first-of-type
                animation-delay: 0s
            &:nth-of-type(2)
                animation-delay: .15s
            &:nth-of-type(3)
                animation-delay: .3s
            &:nth-of-type(4)
                animation-delay: .45s
            &:nth-of-type(5)
                animation-delay: 0.6s
            &:nth-of-type(6)
                animation-delay: 0.75s
            &:last-of-type
                animation-delay: 0.9s

.search-big-loader
    width: 100%
    height: 100%
    padding: 40px 90px 0
    h1
        font-size: 30px
        line-height: 38px
        text-align: center
        font-weight: 100
        margin-bottom: 7px
    h2
        text-align: center
        font-weight: 300
        font-size: 15px
        line-height: 18px
        margin-bottom: 20px
        color: $color-text-light
    canvas
        width: 100%
        height: 100%
        max-width: 1300px
    @include phone
        padding: 20px 20px 0 20px
        h2
            margin-bottom: 40px
