.ant-picker-range {
    width: 100%;
}

.ant-picker {
    .ant-picker-range-separator {
        padding: 0 6px;
    }
}

.ant-btn-dangerous.ant-btn-primary.react-js-cron-clear-button {
    background: #f3f3f3;
    border-color: #e3e3e3;
    color: #444444;
}

.ant-picker-time-panel-column:after {
    height: 0;
}
