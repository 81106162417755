.ant-table small {
    color: #777777;
}

.row-link {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.arrow-holder {
    margin-right: 20px;
    span {
        background: #edf1f9;
        color: #5d7285;
        border-radius: 20px;
        display: inline-block;
        width: 22px;
        height: 22px;
        text-align: center;
        padding-top: 2px;
    }
}

.ant-table-row-level-1 {
    background-color: #fafafa;
}

.selected-row .ant-table-cell {
    background-color: #edf1f9;
}

.primary-row .ant-table-cell {
    background-color: #f9a61b1a;
}

.nested-table {
    th {
        width: 25%;
    }
    .ant-table-row-expand-icon-cell {
        display: none;
    }
}

.child-table {
    tr {
        background-color: #fafafa;
    }

    .ant-table {
        margin-left: 0 !important;
    }
}
