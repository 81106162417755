@import "./base/colors"
@import "./components/side-menu"
@import "./components/loader"
@import "./icons"

[dir]
    main
        padding: 0 24px 160px

    .ant-layout-content
        min-height: 100vh
        background: #ffffff

    .ant-menu-horizontal
        margin: 0 -64px 24px
        padding: 0 44px

    .ant-page-header
        padding: 8px 0 32px
        & + .ant-menu-horizontal
            margin-top: -24px

    .ant-table
        a
            color: $color-blue

    .ant-collapse
        background: #F7F8FC

.main-page
    background: url('./images/welcome-bg.svg') right bottom no-repeat
    padding: 0 64px 160px
    margin: 0 -64px -160px
    min-height: 100vh
    width: calc(100% + 128px)
    ul
        margin-top: 5px
        padding-left: 16px
        li
            margin-top: 10px
            font-size: 15px

.green
    color: $color-green

.red
    color: $color-red

.link
    color: $color-blue

.gray
    color: $color-text-light

.upload-file-block
    .ant-form-item-control-input-content > span
        display: flex
        flex-direction: column-reverse
        gap: 15px
