.ant-modal-footer {
    button {
        width: 50%;
        margin: 0 !important;
        border-radius: 0;
        border: 0;
        box-shadow: none;
        outline: none;
    }
}

.modal-cancel-btn {
    border-right: 1px solid #b9b9b9 !important;
}
