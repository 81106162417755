.ant-transfer {
    margin-bottom: 30px;
    &.rotated {
        flex-direction: row-reverse;
        .ant-transfer-operation {
            transform: rotate(180deg);
            .ant-btn {
                margin-bottom: 10px;
                border-radius: 30px;
            }
        }
    }

    [class*='disabled'],
    [class*='list'] {
        background-color: #fff;
        color: #000;
    }
}
