[dir]
    .ant-menu-vertical
        border-right: 0
        margin: 0 0 40px

    .ant-layout-sider, .ant-menu-vertical
        background: #F7F8FC

    .ant-layout-sider-light .ant-layout-sider-trigger
        background: #EDF1F9

    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected
        background: #EDF1F9
        &, a
            color: #232323

    .ant-menu-item-icon
        width: 22px
        height: 22px
        vertical-align: text-bottom
        color: #5D7285

    .ant-layout-sider-collapsed
        .ant-menu-vertical
            margin: 0 0 20px
        .navigation-subtitle
            display: none
        .logo-wrapper
            margin-bottom: 10px
            img
                width: 50px

.navigation-subtitle
    margin: 0 0 8px 16px
    color: #5D7285
    font-size: 10px
    font-weight: 700
    text-transform: uppercase

.logo-wrapper
    display: block
    text-align: center
    padding: 0 0 15px
    background: #EDF1F9
    margin-bottom: 16px
    height: 71px

.logo-wrapper img
    width: 104px
